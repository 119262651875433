<template>
  <page-content>
    <v-toolbar class="mb-5" flat>
      <v-toolbar-title></v-toolbar-title>
      <v-tabs v-model="selectedTab" center-active>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="'#' + tab.to">{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <v-btn outlined class="mx-1" color="teal darken-2" @click="newNote">
        <v-icon class="mr-1">mdi-note-plus-outline</v-icon>Notatka
      </v-btn>
      <v-btn outlined color="teal darken-2" @click="edit" v-if="user.permissions.site.change">
        <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
        Edycja
      </v-btn>
    </v-toolbar>
    <v-alert class="mt-3" v-if="notice.info" color="info" dark>
      {{ notice.info }}
    </v-alert>
    <site-notes :notes="notes" deletable></site-notes>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="info" :transition="false">
        <material-card :title="site.code_name">
          <template #subtitle
            >{{ site.name }}
            <chip-blocked v-if="!site.is_active"></chip-blocked>
          </template>
          <v-card-subtitle>
            <site-address :site="site"> </site-address>
          </v-card-subtitle>
          <v-card-title>Informacje</v-card-title>
          <v-card-subtitle>{{ site.info }}</v-card-subtitle>
          <v-card-title>Wskazówka dla patrolu</v-card-title>
          <v-card-subtitle>{{ site.patrol_hint }}</v-card-subtitle>
          <v-card-title>Hasło</v-card-title>
          <v-card-subtitle>{{ site.password }}</v-card-subtitle>
          <v-card-title>Kontrahent</v-card-title>
          <v-card-subtitle
            >{{ site.contractor_id }} / {{ site.guid }}</v-card-subtitle
          >
          <template #actions>
            <v-icon class="mr-2" small v-text="info.actionIcon" />
            <v-chip v-for="(tag, index) of tags" :key="index" small>
              {{ index }}. {{ tag }}
            </v-chip>
            <v-spacer></v-spacer>
            <div class="text-truncate">
              {{ info.actionText }}
            </div>
          </template>
        </material-card>
      </v-tab-item>
      <v-tab-item value="events" :transition="false">
        <site-events
          :events="events"
          :pages="eventsPages"
          :count="eventsCount"
          siteHidden
          @refresh-request="refreshEvents"
          @download-request="downloadEvents"
        ></site-events>
      </v-tab-item>
      <v-tab-item value="tasks" :transition="false">
        <app-tasks-site :site="site"></app-tasks-site>
      </v-tab-item>
      <v-tab-item value="password" :transition="false">
        <material-card title="Hasło">
          <v-card-text>
            <v-col cols="6" class="black--text">
              <v-col class="font-weight-medium">{{ site.password }}</v-col>
            </v-col>
          </v-card-text>
          <template #actions>
            <v-icon class="mr-2" small v-text="info.actionIcon" />
            <v-chip v-for="(tag, index) of tags" :key="index" small>
              {{ index }}. {{ tag }}
            </v-chip>
            <v-spacer></v-spacer>
            <div class="text-truncate">
              {{ info.actionText }}
            </div>
          </template>
        </material-card>
      </v-tab-item>
      <v-tab-item value="location" :transition="false">
        <material-card v-bind="this.map">
          <v-card-text>
            <v-sheet>
              <gmaps-map :options="map.options" style="height: 400px">
                <gmaps-marker
                  v-for="(item, i) in map.markers"
                  :key="i"
                  :options="item.options"
                />
              </gmaps-map>
            </v-sheet>
          </v-card-text>
        </material-card>
      </v-tab-item>
      <v-tab-item value="members" :transition="false">
        <invite-site-member-card
          @submit="invite"
          @new-user="newUser"
        ></invite-site-member-card>
        <site-member-card :site="site" ref="membersView"></site-member-card>
      </v-tab-item>
    </v-tabs-items>
  </page-content>
</template>

<script>
import { gmapsMap, gmapsMarker } from "x5-gmaps"
// import gmap  from '@/components/Map.vue'
// import TaskService from '@/services/task.service'
import SiteService from "@/services/site.service"
import EventService from "@/services/event.service"
import { format, parseJSON } from "date-fns"
import SiteAddress from "../components/SiteAddress.vue"
import { sync } from 'vuex-pathify'
import DownloadEventsDialog from "@/components/DownloadEventsDialog.vue"

export default {
  name: "App",
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { id: 0, name: "Informacje", to: "info" },
        { id: 1, name: "Lokalizacja", to: "location" },
        { id: 2, name: "Dostęp", to: "members" },
        { id: 3, name: "Zdarzenia", to: "events" },
        { id: 4, name: "Interwencje", to: "tasks" }
      ],
      site: {},
      notice: {
        info: null
      },
      info: {
        actionIcon: null, //'mdi-tag',
        actionText: null, //'Tracked from Google Analytics',
        color: "primary",
        icon: "mdi-home",
        header: "K101",
        title: "",
        subtitle: ""
      },
      password: {
        actionIcon: null, //'mdi-tag',
        actionText: null, //'Tracked from Google Analytics',
        color: "primary",
        icon: "mdi-key",
        title: "",
        subtitle: ""
      },
      notes: [],
      events: [],
      eventsPages: 0,
      eventsCount: 0,
      map: {
        options: {
          zoom: 15,
          center: { lat: 0, lng: 0 }
        },
        markers: [],
        actionIcon: "mdi-tag",
        actionText: "",
        color: "primary",
        title: "",
        subtitle: ""
      }
    }
  },
  components: {
    gmapsMap,
    gmapsMarker,
    SiteAddress
  },
  watch: {
    ChipBlockedselectedTab(value) {
      console.log("new selected", value)
    }
  },
  computed: {
    user: sync('user.profile'),
    tags() {
      if (!this.site?.tags) {
        return []
      }
      return this.site.tags
        .split("#")
        .map(tag => tag.trim())
        .filter(tag => tag.length > 0)
    }
  },
  methods: {
    invite({user, accessLevel}) {
      console.log("Invited users", user, accessLevel)
      // for (const user of users) {
        console.log("member", user)
        SiteService.createMember(this.site.id, {
          access_level: accessLevel,
          user_id: user.id
        }).then(member => {
          this.$refs.membersView.add(member)
        })
      // }
    },
    newUser() {
      console.log("new user - route")
      this.$router.push({
        name: "Site New User",
        params: { user: {}, site: this.site }
      })
    },
    mediumData(value) {
      return parseJSON(value).toLocaleString(window.navigator.language, {
        dateStyle: "medium"
      })
    },
    newNote() {
      this.$router.push({
        name: "New Note",
        query: { site: this.site.id },
        params: { site: this.site }
      })
    },
    edit() {
      this.$router.push({ name: "Edit Site", params: { site: this.site } })
    },
    refreshEvents(context) {
      EventService.fetchSiteEvents(
        this.$route.params.siteId,
        context.query,
        context?.page,
        context?.ordering
      ).then(response => {
        this.eventsPages = response.data.pages
        this.eventsCount = response.data.count
        this.events = response.data.results
      })
    },
    async downloadEvents(context) {
       const result = await this.$dialog.show(DownloadEventsDialog, {
        title: "",
        waitForResult: true,
      });
      if (result === false || result === undefined) {
        return;
      }
      console.log('this.result :>> ', result);
      var query = {
        ...context.query,
        ...result,
      }
      EventService.download(
        this.$route.params.siteId,
        query
      ).then(response => {
        console.log("ok", response.data)
        const blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8;"
        })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = `${this.site.code_name.replace(/ /g, "_")}_${format(
          new Date(),
          "yyyMMddHHmmss"
        )}.pdf`
        link.click()
        URL.revokeObjectURL(link.href)
      })
    }
  },
  beforeMount() {
    const siteId = this.$route.params.siteId
    SiteService.get(siteId)
      .then(response => {
        this.site = response.data
        let data = response.data
        this.info.header = data.code_name
        this.info.title = data.name
        this.info.subtitle = data.info
        const createdAt = this.mediumData(data.created_at)
        let modifiedAt = this.mediumData(data.modified_at)
        if (modifiedAt == createdAt) {
          modifiedAt = ""
        } else {
          modifiedAt = `Ostatnia modyfikacja: ${modifiedAt}`
        }
        this.info.actionText = `Utworzono: ${createdAt} ${modifiedAt}`
        this.info.actionIcon = data.tags.length > 0 ? "mdi-tag" : null
        this.password.title = data.password
        this.notice.info = data.notice
        this.map.options.center = {
          lng: data.location.coordinates[0],
          lat: data.location.coordinates[1]
        }
        this.map.title = `${data.city} ${data.district}`
        this.map.subtitle = data.address
        this.map.markers = [
          {
            options: {
              position: {
                lng: data.location.coordinates[0],
                lat: data.location.coordinates[1]
              }
            }
          }
        ]
        // this.map.iframe = data.location
        // this.info.title = this.data.name
      })
      .catch(error => {
        // TODO error redirect
        console.error("Error", error)
      })

    SiteService.getNotes(siteId).then(response => {
      this.notes = response.data
    })
  }
}
</script>
